import React from "react";


const WhatToExpect = () => {


  return (
    <div className="container">

    <div className="row no-gutters justify-content-between pt-24">
        <div className="col-lg-12">
            <div className="blogPost -single -light">
                <div className="blogPost__content">
                    <h2 className="headings">What To Expect</h2>
                    <hr></hr>
              
                    <div className="row x-gap-60 y-gap-48">
                        <p>
                            At your initial visit, your provider will obtain your health history, examine your health records as
                            necessary, perform a through evaluation of your legs and discuss treatment options. You will be given
                            ample time to get your questions answered regarding the treatment. Your will be advised regarding the
                            necessity for ultrasound at subsequent visit. Depending on your insurance, you may be able to get the
                            ultrasound during your initial consultation. Once the ultrasound is complete, we will be able to discuss
                            the diagnosis, treatment plan and estimated number of treatments. You will asked for the referral form
                            from your primary care provider if required by your insurance plan. Our staff will assist you with billing
                            and payments.
                        </p>
                        <p>
                            On the day of treatment you will be asked to wear loose fitting clothes/shorts. Avoid using body
                            creams/perfumes. If your have takes a sedative prior to the procedure, you will be required to have
                            someone drive you back after the procedure. Compression stockings are to be worn right after the
                            procedure. You are required to bring them to the appointment. If you do not have them or forgot at
                            home, you may purchase them from our office.
                        </p>
                        <p>
                            Most patients have minimal pain from the procedure and are generally back to daily activities by next
                            day. Some procedures may result in extensive bruising that resolves in 10-14 days. You may use
                            ibuprofen or Tylenol for pain as needed. Call our office if you have severe pain. Cord like appearance
                            along the vein, tingling or burning are experienced by some patients but these symptoms resolve within
                            2 weeks.
                        </p>
                    </div>
                
                </div>
            </div>

        </div>
    </div>

</div>
  );
};

export default WhatToExpect;
