import React from "react";
import ImgDr from "../../assets/images/apex/Dr.jpg";
import ImgDrCertificate from "../../assets/images/apex/Doctor-certificate.png";

const About = () => {
  
  return (
    <div className="container">

          <section>
          <h2 className="headings">About Us</h2>
          <hr></hr>
        <div className="container">
       
            <div className="row no-gutters justify-content-between">
                <div className="col-lg-12">
                    <div className="blogPost -single -light">
                        <div className="blogPost__content pt-48">
                            <div className="row x-gap-60 y-gap-48">

                                <div className="col-5 col-lg-3">
                               
                                    <img src={ImgDr} alt="Dr. Meka" className="img-fluid" style={{ maxWidth: '100%', height: 'auto' }} />
                                </div>
                                <div className="col-12 col-lg-6 mobile-order-3">
                                    <p className="py-2">
                                        Dr. Meka received her medical degree in 2001 from Gandhi Medical College, Hyderabad, India. It is one of the oldest and largest medical schools in India, graduating 150 students every year.
                                    </p>
                                    <p className="py-2">
                                        Dr. Meka received training in venous valve repairs, deep venous reconstructions, and perforators ablations during medical school. During her General Surgery training at Memorial Health University in Georgia, she developed core vascular surgery operating skills. She also presented and published several vascular papers during her residency. She then completed advanced training in endovascular techniques during her vascular surgery fellowship.
                                    </p>
                                    <p className="py-2">
                                        Dr. Meka completed her Vascular Surgery Fellowship at the University of Arkansas in 2011. After Fellowship, Dr. Meka worked as a consulting vascular surgeon at Harris Methodist hospital in Texas. Following this, she took academic vascular surgery position at VA Medical Center in Phoenix, in affiliation with Banner University Medical Center. She holds a position as Clinical Assistant Professor and is double board-certified by the American Board of Vascular Surgery and American Board of Vascular Medicine.
                                    </p>
                                    <p className="py-2">
                                        Dr. Meka is a fellow of society for vascular Surgery and participates in several committees involved in creating guidelines for Vascular Care.

                                    </p>
                                    <h5>
                                        CURRENT CERTIFICATES
                                    </h5>
                                    <div className="text-base ml-minus-4 mt-32">
                                        <div className="d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"    className="feather feather-check size-2xs str-width-lg text-accent mr-8"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            <p className="mb-0">American Board of Surgery - Vascular Surgery</p>
                                        </div>
                                        <div className="d-flex align-items-center mt-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"    className="feather feather-check size-2xs str-width-lg text-accent mr-8"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            <p className="mb-0">American Board of Vascular Medicine - Endovascular Medicine</p>
                                        </div>
                                        <div className="d-flex align-items-center mt-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"    className="feather feather-check size-2xs str-width-lg text-accent mr-8"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            <p className="mb-0">Registered Physician in Vascular Interpretation</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 col-lg-3 mobile-order-2">
                                  
                                    <img src={ImgDrCertificate} alt="Dr. Meka" className="img-fluid" style={{ maxWidth: '100%', height: 'auto' }} />

                                </div>
                                    </div>
                        
                        </div>
                    </div>

                </div>
            </div>
      
        </div>
 
    </section>
    </div>
  );
};

export default About;
