import React from "react";

// Subcomponent for Section Heading
const SectionHeading = ({ title }) => <h2 className="headings">{title}</h2>;

// Subcomponent for rendering a list of paragraphs
const ParagraphList = ({ paragraphs }) => (
  <>
    {paragraphs.map((text, index) => (
      <p key={index} className="py-2 mb-0">
        {text}
      </p>
    ))}
  </>
);

// Subcomponent for rendering lists with check icons
const CheckList = ({ items }) => (
<ul className="py-2 mb-0 no-bullets">
    {items.map((item, index) => (
      <li key={index}>
        <span className="icon-check mr-2">✔️</span>
        {item.text}
        {/* If the item has a sublist, render it recursively */}
        {item.subItems && (
          <ul className="px-4">
            <CheckList items={item.subItems} />
          </ul>
        )}
      </li>
    ))}
  </ul>
);

// Main Component
const VenousDisease = () => {
  const varicoseVeinsContent = [
    "Varicose veins are swollen, blue, bulging, twisted, superficial (those closest to the skin) veins of the leg. High pressure inside the superficial veins of the leg causes varicose veins, but genetics are thought to play a large, contributing role.",
    "As many as 40 million Americans have varicose veins. Left untreated, varicose veins may become worse. Persons with varicose veins often experience leg aches and fatigue. They may also incur skin changes such as rashes, redness, and ulcers."
  ];

  const riskFactors = [
    { text: "Age: People between the ages of 30 and 70 often have varicose veins." },
    { text: "During pregnancy, 50 to 55 percent of American women experience varicose veins." },
    { text: "In most cases, the veins return to normal within a year after childbirth." },
    { text: "Women who have multiple pregnancies may develop permanent varicose veins." },
    {
      text: "The varicose vein risk factors for women and men include:",
      subItems: [
        { text: "Family history of varicose veins" },
        { text: "Being overweight" },
        { text: "Standing or sitting for long periods of time" }
      ]
    }
  ];

  const lifestyleRecommendations = [
    "Maintaining the proper weight",
    "Elevating legs when resting",
    "Not sitting or standing for long periods of time",
    "Wearing compression (elastic support) stockings"
  ];

  const treatmentOptions = [
    {
      title: "Sclerotherapy",
      description:
        "Sclerotherapy is the sealing of the veins via an injection of a medication. This procedure is done mainly for smaller spider veins. It is performed in a doctor’s office. The physician injects a chemical into the varicose veins to prevent the veins from filling with blood."
    },
    {
      title: "Vein Stripping",
      description:
        "Vein Stripping is performed for more involved varicose veins. The physician may make two small incisions, one in the groin area and another below the knee, through which the diseased veins are removed. This is an outpatient procedure, usually done under general anesthesia. Patients can resume their normal activities after four weeks."
    },
    {
      title: "Ablation and Laser treatment",
      description:
        "Laser treatments use a tiny fiber placed in the vein through a catheter. The fiber sends out laser energy that closes the diseased portion of the vein. These two modes of treatment frequently replace stripping of the saphenous vein. They can be performed alone or in conjunction with removal of individual clusters of varicose veins known as small incision avulsion or ambulatory phlebectomy."
    }
  ];

  return (
    <div className="container">
      <div className="row no-gutters justify-content-between pt-1">
        <div className="col-lg-12">
          <div className="blogPost -single -light">
            <div className="blogPost__content">
            <h2 className="headings">Varicose Veins</h2>
            <hr></hr>
              <div className="row x-gap-60 y-gap-48">
                <ParagraphList paragraphs={varicoseVeinsContent} />
              </div>

              <div>
                <h5>What factors influence development of VARICOSE VEINS?</h5>
                <CheckList items={riskFactors} />
              </div>

              <div>
                <h6>Most doctors will suggest lifestyle changes for patients with varicose veins. This includes:</h6>
                <CheckList items={lifestyleRecommendations.map(text => ({ text }))} />
              </div>

              <div>
                <h6>In some cases, a vascular surgeon will recommend additional treatments for ridding the legs of VARICOSE VEINS:</h6>
                <div className="row x-gap-60 y-gap-48">
                  {treatmentOptions.map((option, index) => (
                    <div key={index} className="treatment-option">
                      <h6 className="font-bold">{option.title}</h6>
                      <p className="py-2 mb-0">{option.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenousDisease;
