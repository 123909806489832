import { useEffect, useState } from 'react';

function LocationFetcher({ onLocationUpdate }) {
    const [coordinates, setCoordinates] = useState({ lat: null, lon: null });

    const getUserCoordinates = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCoordinates({
                        lat: position.coords.latitude,
                        lon: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error('Error obtaining location', error);
                }
            );
        }
    };

    useEffect(() => {
        getUserCoordinates();
    }, []);

    useEffect(() => {
        if (coordinates.lat && coordinates.lon) {
            // Fetch location details using the Nominatim API
            const apiLocationURL=process.env.REACT_APP_Location_URL;
            fetch(apiLocationURL+`?format=json&lat=${coordinates.lat}&lon=${coordinates.lon}&zoom=18&addressdetails=1`)
                .then(response => response.json())
                .then(data => {
              
                    const locationData = {
                        fullAddress: '',
                        city: data.address.city || data.address.town || data.address.village || data.address.municipality || 'Unknown city',
                        state: data.address.state || 'Unknown state',
                        country: data.address.country || 'Unknown country'
                    };
                    locationData.fullAddress = `${data.address.road || ''}, ${data.address.city || data.address.town || data.address.village || data.address.municipality || 'Unknown city'}, ${data.address.state || 'Unknown state'}, ${data.address.country || 'Unknown country'}`.trim().replace(/^,|,$/g, '');
                    onLocationUpdate(locationData); // Send data back to the parent component
                })
                .catch(error => {
                    console.error('Error fetching location details:', error);
                });
        }
    }, [coordinates, onLocationUpdate]);

}

export default LocationFetcher;
