import React, { useState } from "react";
import { submitData } from "../../services/ApexExternalAPI"; // Import the service
import Logo from '../../assets/images/logo.png';
import { UserInformationModel } from "../models/UserInformationModel";
import { ContactusEmailModel } from "../models/ContactusEmail";
import IPFetcher from '../common/IPFetcher';
const Contact = () => {
  const [formData, setFormData] = useState({ name: "", email: "", phonenumber: "", message: "" });
  const [userInfo, setUserInfo] = useState(() => new UserInformationModel());
  const [contactusEmailInfo, setContactusEmailInfo] = useState(() => new ContactusEmailModel());
  
  const [userIP, setUserIP] = useState(''); // Fetch IP
  const [locationData, setLocation] = useState({
    fullAddress:'',
    city: '',
    state: '',
    country: ''
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    //alert(`Thank you ${formData.name}, we will contact you soon.`);
    setFormData({ name: "", email: "", phonenumber: "", message: "" });
    sendEmail();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleLocationFetched = (data) => {
    // Set the Location address
    setLocation(data);
  
    // Use previous state to ensure existing values are not overwritten
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      userLocation: data.fullAddress, // Updated to use data instead of locationData
    }));
  };
  
  // Get IP Details
  const handleIP = (ip) => {
    setUserIP(ip);
  
    // Use previous state to ensure existing values are not overwritten
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      userIPAddress: ip,
    }));
  };
  const sendEmail =async (e) =>{
    try {
      const contactusEmail = {
        name: formData.name,
        email: formData.email,
        phonenumber: formData.phonenumber,
        message:formData.message,
      }
      const contactusDTO = {
        contactusEmail: contactusEmail,
        userInformation: userInfo
      };
      const response = await submitData(contactusDTO, "ContactUs");
      // Handle the response accordingly
    } catch (error) {
      console.error("Failed to submit questionnaire:", error);
    }
  }
  return (
    <div className="container">
      <IPFetcher onIPReceived={handleIP} />
      <h2 className="headings">Contact Us</h2>
      <hr></hr>
      <div className="row">
      <div className="text-dark col-lg-4">
  Apex Vein & Vascular Institute, PC<br />
  4550 E Bell Rd<br />
  Building 8, Suite 280<br />
  Phoenix, AZ 85032<br />
  Phone: <a href="tel:6232524495">623 252 4495</a><br />
  Email: <a href="mailto:bannerlegs@gmail.com">bannerlegs@gmail.com</a>
</div>


        <div className="col-lg-4 ">

          <div>

            <form onSubmit={handleSubmit}>
              <div>
                <label>Name:</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required />
              </div>
              <div>
                <label>Email:</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
              </div>
              <div>
                <label>Phone number:</label>
                <input
                  type="text"
                  name="phonenumber"
                  value={formData.phonenumber}
                  onChange={handleChange}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  required
                />
              </div>
              <div>
                <label>Message:</label>
                <textarea name="message" value={formData.message} onChange={handleChange} required />
              </div>
              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default Contact;
