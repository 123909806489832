import React from "react";

const PrivacyPolicy = () => {
  return (
    
    <div className="container">
      <div className="row no-gutters justify-content-between">
        <div className="col-lg-12">
          <div className="blogPost -single -light">
            <div className="blogPost__content">
              <h2 className="headings">Privacy Policy</h2>
              <hr />
              <div className="row x-gap-60 y-gap-48">
                <div className="col-lg-12">
                  <p>
                    <strong>Apex Vein & Vascular Institute, PC</strong> <br />
                    <strong>Effective Date:</strong> October 12, 2024
                  </p>
                  <p>
                    Apex Vein & Vascular Institute, PC ("we," "us," or "our")
                    is committed to protecting your privacy. This Privacy
                    Policy explains how we collect, use, disclose, and safeguard
                    your information when you visit our website,{" "}
                    <a href="https://www.apexveinandvascular.com">
                      apexveinandvascular.com
                    </a>
                    , or contact us by phone or email. Please read this policy
                    carefully to understand our practices regarding your
                    personal data.
                  </p>

                  <h4>1. Information We Collect</h4>
                  <p>We may collect the following types of information from you:</p>
                  <ul>
                    <li>
                      <strong>Personal Identifiable Information (PII):</strong> This may include your name, email address, phone number, mailing address, and any other information you provide when contacting us via our website, email, or phone.
                    </li>
                    <li>
                      <strong>Health Information:</strong> If you request medical advice or services, we may collect information related to your health history, treatments, and medical conditions.
                    </li>
                    <li>
                      <strong>Usage Data:</strong> We automatically collect certain data about your interaction with our website, such as your IP address, browser type, operating system, and pages you visited.
                    </li>
                  </ul>

                  <h4>2. How We Use Your Information</h4>
                  <p>We use the information we collect for various purposes, including:</p>
                  <ul>
                    <li>To respond to your inquiries and provide customer service;</li>
                    <li>To schedule appointments or follow up on medical inquiries;</li>
                    <li>To improve and optimize the functionality of our website;</li>
                    <li>To comply with legal and regulatory obligations.</li>
                  </ul>

                  <h4>3. Sharing Your Information</h4>
                  <p>We may share your personal information in the following circumstances:</p>
                  <ul>
                    <li>
                      <strong>With Service Providers:</strong> We may share information with third-party vendors or partners that provide services on our behalf, such as website hosting, data analysis, or payment processing.
                    </li>
                    <li>
                      <strong>For Legal Compliance:</strong> We may disclose your information to comply with any legal process, government request, or applicable law.
                    </li>
                    <li>
                      <strong>With Your Consent:</strong> We may share information with third parties when you explicitly provide consent.
                    </li>
                  </ul>

                  <h4>4. Cookies and Tracking Technologies</h4>
                  <p>
                    Our website may use cookies and similar tracking technologies to enhance your browsing experience and analyze website traffic. You can control cookies through your browser settings.
                  </p>

                  <h4>5. Data Security</h4>
                  <p>
                    We take reasonable measures to protect the confidentiality, integrity, and availability of your information. However, no transmission over the Internet is completely secure, and we cannot guarantee the security of any data transmitted to or from our website.
                  </p>

                  <h4>6. Your Rights</h4>
                  <p>You have the right to:</p>
                  <ul>
                    <li>Access the personal information we hold about you;</li>
                    <li>Request correction of any inaccurate or incomplete data;</li>
                    <li>Request deletion of your personal data under certain circumstances;</li>
                    <li>Opt-out of receiving marketing communications.</li>
                  </ul>
                  <p>
                    To exercise any of these rights, please contact us at{" "}
                    <a href="mailto:contact@bannerlegs.com">
                      contact@bannerlegs.com
                    </a>{" "}
                    or by phone at 623-252-4495 (ext. 1).
                  </p>

                  <h4>7. Third-Party Links</h4>
                  <p>
                    Our website may contain links to third-party websites that
                    are not operated by us. We are not responsible for the
                    content or privacy practices of these external sites. We
                    encourage you to review the privacy policies of any
                    third-party websites you visit.
                  </p>

                  <h4>8. Children's Privacy</h4>
                  <p>
                    Our services are not directed to individuals under the age
                    of 18, and we do not knowingly collect personal information
                    from children. If we become aware that we have collected
                    personal data from children without parental consent, we
                    will take steps to delete that information.
                  </p>

                  <h4>9. Changes to This Privacy Policy</h4>
                  <p>
                    We may update this Privacy Policy from time to time. Any
                    changes will be posted on this page with an updated
                    effective date. We encourage you to review this policy
                    periodically.
                  </p>

                  <h4>10. Contact Us</h4>
                  <p>
                    If you have any questions about this Privacy Policy or our
                    practices, please contact us at:
                  </p>
                  <address>
                    <strong>Apex Vein & Vascular Institute, PC</strong> <br />
                    4550 E Bell Rd, STE 280 <br />
                    Phoenix, AZ 85032 <br />
                    Phone: 623-252-4495 (ext. 1) <br />
                    Email:{" "}
                    <a href="mailto:contact@bannerlegs.com">
                      contact@bannerlegs.com
                    </a>
                  </address>
                  <p>
                    By using our website or services, you agree to the terms
                    outlined in this Privacy Policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
