export class ScreeningModel {
    constructor() {
        this.veinConcern = ""; // Question 1
        this.conditions = []; // Question 2 (checkboxes)
        this.symptoms = []; // Question 3 (checkboxes)
        this.familyHistory = ""; // Question 4
        this.pastTreatment = ""; // Question 5
        this.otherSymptom = ""; // For Question 3 (Other)
      }
  
    // Method to convert to JSON for the API request
    toJSON() {
      return {
        veinConcern: this.veinConcern,
        conditions: this.conditions,
        symptoms: this.symptoms,
        familyHistory: this.familyHistory,
        pastTreatment: this.pastTreatment,
        otherSymptom: this.otherSymptom,
      };
    }
  }