import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

import Logo from '../../../assets/images/logo_new.jpg';

const Navbar = () => {
  // State to handle the toggle behavior for mobile menu
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  // Toggle the collapse class based on state
  const handleToggle = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        {/* Logo Section */}
        <Link className="navbar-brand" to="/">
          <img src={Logo} alt="Apex Vein & Vascular Institute, PC" className="navbar-logo" />
        </Link>

        {/* Toggler Button */}
        <button
          className={`navbar-toggler ${isNavCollapsed ? 'collapsed' : ''}`}
          type="button"
          aria-controls="navbarNav"
          aria-expanded={!isNavCollapsed}
          aria-label="Toggle navigation"
          onClick={handleToggle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Links Section */}
        <div className={`collapse navbar-collapse ${isNavCollapsed ? '' : 'show'}`} id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link className="nav-link text-white" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/VenousDisease">Venous Disease</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/Procedures">Procedures</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/WhatToExpect">What To Expect</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/Covid19">Covid-19</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/About">About Us</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/contact">Contact</Link>
            </li>
      
          </ul>
        </div>

        {/* Book Now Button */}
        <div className="d-flex justify-content-center flex-wrap">
  
        <a
            href="https://provider.kareo.com/madhavi-meka#?view=booking#booking"
            className="btn schedule-btn mb-2 me-2"
          >
            Schedule Now
          </a>
          <a
            href="tel:623-252-4495"
            className="btn call-btn mb-2 me-2"
          >
            Call Now
          </a>
          <Link className="btn screening-btn mb-2 me-2" to="/screening">
            Screening
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
