import React,{ useEffect,useState  } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/pages/Header/Navbar";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import BookNow from "./components/pages/BookNow";
import Screening from "./components/pages/Screening";
import Footer from './components/pages/Footer/Footer';
import VenousDisease from './components/pages/VenousDisease';
import Procedures from './components/pages/Procedures';
import WhatToExpect from './components/pages/WhatToExpect';
import Covid19 from './components/pages/Covid19';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import useUserTracking  from '../src/components/common/UserTracker'; // Adjust the path as necessary

function App() {
  useUserTracking();
   return (
    
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/book" element={<BookNow />} />
        <Route path="/screening" element={<Screening />} />
        <Route path="/VenousDisease" element={<VenousDisease />} />
        <Route path="/Procedures" element={<Procedures />} />
        <Route path="/WhatToExpect" element={<WhatToExpect />} />
        <Route path="/Covid19" element={<Covid19 />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </Router>
    
  );
}

export default App;
