import React, { useState, useEffect } from 'react';
import axios from 'axios';

const IPFetcher = ({ onIPReceived }) => {
  const [ip, setIP] = useState('');

  const getUserIPandLocation = async () => {
    try {
      // Fetch IP Address
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      const userIP = ipResponse.data.ip;
      setIP(userIP);
      
      // Send IP address to the parent component via the onIPReceived prop
      onIPReceived(userIP);
   
    } catch (error) {
      console.error("Error fetching IP or location data:", error);
    }
  };

  useEffect(() => {
    getUserIPandLocation();
  }, []);

};

export default IPFetcher;
