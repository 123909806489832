import React from 'react';
import "../../styles/components/Procedures.css";
import Img1Before from "../../assets/images/apex/image1_before.jpg"; // Before Image 1
import Img1After from "../../assets/images/apex/image1_after.jpg"; // After Image 1
import Img2Before from "../../assets/images/apex/image2_before.jpg"; // Before Image 2
import Img2After from "../../assets/images/apex/image2_after.jpg"; // After Image 2
import Img5Before from "../../assets/images/apex/image5_before.jpg"; // Before Image 5
import Img5After from "../../assets/images/apex/image5_after.jpg"; // After Image 5
import Img6Before from "../../assets/images/apex/image6_before.jpg"; // Before Image 6
import Img6After from "../../assets/images/apex/image6_after.jpg"; // After Image 6
import ImgVarithena from "../../assets/images/apex/procedure-1.jpg";
import ImgRadioVenaSeal from "../../assets/images/apex/radio-vena-seal.jpg";

const BeforeAfterRow = ({ beforeImg, afterImg, beforeText = 'Before', afterText = 'After' }) => (
  <div className="before-after-row">
    <div className="before-after-column">
      <h4 className="overlay-text">{beforeText}</h4>
      <img src={beforeImg} alt={`${beforeText}`} className="before-after-img" />
    </div>
    <div className="before-after-column">
      <h4 className="overlay-text">{afterText}</h4>
      <img src={afterImg} alt={`${afterText}`} className="before-after-img" />
    </div>
  </div>
);

const Procedures = () => {
  return (
    <div className="container">
      {/* Section 1: Services Offered */}
      <section className="services-section">
        <div className="col-lg-12">
          <article className="blogPost -single -light">
            <header className="blogPost__content">
              <h2 className="headings">Services Offered</h2>
              <hr></hr>
            </header>
            <div className="row service-row">
              {/* Service Item 1 */}
              <div className="col-lg-4 col-md-6 service-item">
                <div className="service-content">
                  <h4 className="text-xl fw-600">Sclerotherapy</h4>
                  <p className="mt-16">
                    The least invasive method of eliminating varicose veins, also used for spider veins.
                  </p>
                </div>
              </div>
              <figure className="col-lg-3 service-img-container">
                <img src={ImgVarithena} alt="Sclerotherapy Procedure" className="service-img" />
              </figure>
              {/* Service Item 2 */}
              <div className="col-lg-3 col-md-6 service-item">
                <div className="service-content">
                  <h4 className="text-xl fw-600">Varithena</h4>
                  <p className="mt-16">
                    Large superficial veins are eliminated by injecting a foam, which is more effective than liquid sclerotherapy.
                  </p>
                </div>
              </div>
              {/* Service Item 3 */}
              <div className="col-lg-4 col-md-6 service-item">
                <div className="service-content">
                  <h4 className="text-xl fw-600">Radiofrequency Ablation</h4>
                  <p className="mt-16">
                    RFA, or closure procedure, is a method of ablating leaky superficial veins using heat.
                  </p>
                </div>
              </div>
              <figure className="col-lg-3 service-img-container">
                <img src={ImgRadioVenaSeal} alt="Radiofrequency Ablation Procedure" className="service-img" />
              </figure>
              {/* Service Item 4 */}
              <div className="col-lg-3 col-md-6 service-item">
                <div className="service-content">
                  <h4 className="text-xl fw-600">VenaSeal</h4>
                  <p className="mt-16">
                    The latest technology available for eliminating diseased superficial veins, using a medical glue.
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>

     {/* Section 2: Before and After */}
     <section className="before-after-section">
        <div className="col-lg-12 text-Left">
          <h2 className="headings">Before and After</h2>
          <hr></hr>
          <p className="section-description">
            See the amazing transformations after our treatments. Each image below showcases a before and after comparison
            of our procedures.
          </p>
        </div>

        {/* Improved Before and After Layout */}
        <div className="before-after-container">
          <BeforeAfterRow beforeImg={Img1Before} afterImg={Img1After}  />
          <BeforeAfterRow beforeImg={Img2Before} afterImg={Img2After} />
          <BeforeAfterRow beforeImg={Img5Before} afterImg={Img5After}  />
          <BeforeAfterRow beforeImg={Img6Before} afterImg={Img6After}  />
        </div>
      </section>
    </div>
  );
};

export default Procedures;