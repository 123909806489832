// src/components/Home.js
import React from "react";
import "../../styles/components/Home.css";
import ImgHomeList from "../../assets/images/home-list.jpg";
import { submitData } from "../../services/ApexExternalAPI";
import { Link } from 'react-router-dom';
import UserDetailsFetcher from '../common/UserDetailsFetcher';
const Home = () => {
    /* const handleUserDetailsFetched = async (userDetails) => {
        console.log('User Details Fetched:', userDetails);
        try {
         
            const userInformation = {
              userInformation: userDetails
            };
            //const response = await submitData(userInformation, "UserInformation");
            // Handle the response accordingly
          } catch (error) {
            console.error("Failed to submit questionnaire:", error);
          }
    }; */
    return (
        <div>
           {/*  <UserDetailsFetcher onUserDetailsFetched={handleUserDetailsFetched} /> */}
            <section>
                <div className="parallax parallax-1">
                    <h4 className="parallax-text-1 top-left-text">
                        <span className="first-character caption">N</span>
                        ow is the time to start loving your legs again.
                        <br />Say goodbye to pain-full heavy tired legs.
                    </h4>


                    <div className="d-flex justify-content-end">
                    <div className="text-center parallax-btn-sc">

                        <h4 className="text-white">We can help get your legs back</h4>


                        <div className="d-flex justify-content-center flex-wrap">

                        </div>

                        <hr />

                        <div className="d-flex justify-content-center flex-wrap">

                            <a
                                href="https://provider.kareo.com/madhavi-meka#?view=booking#booking"
                                className="btn schedule-btn mb-2 me-2"
                            >
                                Schedule Now
                            </a>
                            <a
                                href="tel:623-252-4495"
                                className="btn call-btn mb-2 me-2"
                            >
                                Call Now
                            </a>
                            <Link className="btn screening-btn mb-2 me-2" to="/screening">
                                Screening
                            </Link>
                        </div>
                    </div>
                    </div>
                </div>


            </section>

            <section className="content-section">
                <div className="container text-center">
                    <h2 className="headings">Do you have?</h2>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <ul className="list-unstyled custom-list">
                                    <li className="d-flex justify-content:center align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-circle-fill me-2" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"></path>
                                        </svg>
                                        Enlarged veins on your legs
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-circle-fill me-2" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"></path>
                                        </svg>
                                        Swelling of ankles and calf
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-circle-fill me-2" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"></path>
                                        </svg>
                                        Pain/cramps in your legs
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-circle-fill me-2" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"></path>
                                        </svg>
                                        Bleeding from veins
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-circle-fill me-2" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"></path>
                                        </svg>
                                        Ulcers around ankles
                                    </li>

                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <img src={ImgHomeList} alt="Apex Vein & Vascular Institute, PC" className="img-fluid" style={{ maxWidth: '100%', height: 'auto' }} />
                            </div>
                        </div>
                    </div>


                    <div >
                        <p>
                            <span className="first-character ny">If</span>your answer is yes, you are at the right place to get your questions answered further. At Apex vein and vascular institute we take a comprehensive approach towards your vascular issues. We treat the inner disease to minimize your symptoms and improve the esthetic appearance of your leg. Our treatments are geared towards safer, faster and complete treatment. Treatment options are individualized and may be as simple as compression stockings or invasive: which involves closure of the veins with laser beam, radio frequency waves, glue or sclerosing agents. Dr. Meka and her well experienced staff will provide you the care you need and the attention your legs deserve. Apex vein and vascular institute is a locally owned family friendly vascular center. Dr. Meka is fellowship trained in all aspects of vascular surgery. She specializes in treatment for varicose veins, spider veins, and other vein conditions. She offers minimally invasive approach, radiofrequency ablation, venaseal glue ablation, sclerotherapy, and alternative treatments. With her comprehensive knowledge about the arterial and venous disorders in the body, she can advice you on the best treatment tailored towards your vascular problem rather than just managing the outward appearance of your legs. We help you regain the appearance and lifestyle you deserve. At our institute, we genuinely love helping people feel better by treating veins that have bled, clotted, become disfigured, and even veins that have caused ulcers. We strive not only to provide smooth process but also have passion for helping patients. We want you to leave with great results and feeling like family. Our motto is integrity and excellence. We look forward to seeing you for your initial vein consultation.
                        </p>
                    </div>
                </div>
            </section>


            <section className="parallax parallax-2">
                <div className="parallax-content">
                    <h2 className="text-white">Get Expert Care</h2>

                    {/* Schedule/Call Section */}
                    <h2 className="headings text-white">Ready to take the next step? Schedule an appointment or give us a call!</h2>
                    <div className="d-flex justify-content-center flex-wrap">

                        <a
                            href="https://provider.kareo.com/madhavi-meka#?view=booking#booking"
                            className="btn schedule-btn mb-2 me-2"
                        >
                            Schedule Now
                        </a>
                        <a
                            href="tel:623-252-4495"
                            className="btn call-btn mb-2"
                        >
                            Call Now
                        </a>
                    </div>


                    <hr />

                    <h2 className="headings text-white">Would you like to start with a quick screening?</h2>
                    <div className="d-flex justify-content-center flex-wrap">
                        <Link className="btn screening-btn mb-2 me-2" to="/screening">
                            Screening
                        </Link>
                    </div>
                </div>
            </section>

            <section className="parallax parallax-3">
                <div className="parallax-content">
                    <h2>What To Expect</h2>

                </div>
            </section>

            <section className="d-flex justify-content-center align-items-center">
                <div className="block text-center">
                    <p>
                        <span className="first-character atw">At</span> your initial visit, your provider will obtain your health history, examine your health records as necessary, perform a thorough evaluation of your legs and discuss treatment options. You will be given ample time to get your questions answered regarding the treatment. You will be advised regarding the necessity for ultrasound at subsequent visits. Depending on your insurance, you may be able to get the ultrasound during your initial consultation. Once the ultrasound is complete, we will be able to discuss the diagnosis, treatment plan, and estimated number of treatments. You will be asked for the referral form from your primary care provider if required by your insurance plan. Our staff will assist you with billing and payments.
                    </p>
                    <p className="line-break margin-top-10"></p>
                    <p className="margin-top-10">
                        On the day of treatment, you will be asked to wear loose-fitting clothes/shorts. Avoid using body creams/perfumes. If you have taken a sedative prior to the procedure, you will be required to have someone drive you back after the procedure. Compression stockings are to be worn right after the procedure. You are required to bring them to the appointment. If you do not have them or forgot them at home, you may purchase them from our office.
                    </p>
                    <p className="line-break margin-top-10"></p>
                    <p>
                        Most patients have minimal pain from the procedure and are generally back to daily activities by the next day. Some procedures may result in extensive bruising that resolves in 10-14 days. You may use ibuprofen or Tylenol for pain as needed. Call our office if you have severe pain. A cord-like appearance along the vein, tingling, or burning are experienced by some patients, but these symptoms resolve within 2 weeks.
                    </p>
                </div>
            </section>

        </div>
    );
};

export default Home;
