import React, { useState } from "react";


const BookNow = () => {
  const [appointment, setAppointment] = useState({
    name: "",
    date: "",
    time: ""
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Appointment booked for ${appointment.name} on ${appointment.date} at ${appointment.time}`);
    setAppointment({ name: "", date: "", time: "" });
  };

  return (
    <div className="container">
      <h2>Book Your Appointment</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={appointment.name}
            onChange={(e) => setAppointment({ ...appointment, name: e.target.value })}
            required
          />
        </div>
        <div>
          <label>Date:</label>
          <input
            type="date"
            value={appointment.date}
            onChange={(e) => setAppointment({ ...appointment, date: e.target.value })}
            required
          />
        </div>
        <div>
          <label>Time:</label>
          <input
            type="time"
            value={appointment.time}
            onChange={(e) => setAppointment({ ...appointment, time: e.target.value })}
            required
          />
        </div>
        <button type="submit">Book Now</button>
      </form>
    </div>
  );
};

export default BookNow;
