import React from "react";


const Covid19 = () => {


  return (
    <div className="container">

    <div className="row no-gutters justify-content-between">
        <div className="col-lg-12">
            <div className="blogPost -single -light">
                <div className="blogPost__content">
                    <h2 className="headings">COVID-19</h2>
                    <hr></hr>
                    <div className="row x-gap-60 y-gap-48">
                        <p>
                            All employees and patients will be screened for temperature rise and questions related to COVID 19. We
                            abide by CDC’s mask mandate for healthcare facilities. All employees, patients and visitors are required
                            to follow this. Social distancing measures will be practiced in waiting areas. Waiting rooms will have
                            limited access to clinical brochures and refreshments. We strongly recommend against patient
                            companions. In the event that patient needs a companion for providing help with transportation or
                            translation, we recommend limiting to one companion per patient. <strong>
                                Companions of patients in out-
                                patient locations will also be required to bring their own masks and wear them while inside the facilities.
                                In addition, they will be subject to a health screening prior to entering the building.
                            </strong> All exam rooms and
                            patient waiting areas will be sanitized before and after each encounter with EPA approved disinfectants.
                            PPE will be made available to all providers. Employer shall provide training to employees on COVID-19.
                            This will include work place infection control practices, proper use of PPE, steps employees must follow
                            to appraise employer of COVID-19 symptoms/suspected/confirmed case of COVID-19, and how to
                            report unsafe working conditions.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
  );
};

export default Covid19;
