export class ContactusEmailModel {
    constructor() {
        this.email = "";
        this.name = ""; 
        this.phonenumber = ""; 
        this.message = ""; 
        
      }
  
 // Method to convert to JSON for the API request
 toJSON() {
    return {
        email: this.email,
        name: this.name,
        phonenumber:this.phonenumber,
        message:this.message
    };
  }

}