export const submitData = async (inputData, apiName) => {
    try {

        const apiURL=process.env.REACT_APP_API_URL;
        const response = await fetch(apiURL + apiName, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(inputData),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to submit request. Status: ${response.status}`);
      }
  
      // Check if the response has a body before parsing it
      const isJson = response.headers.get('content-type')?.includes('application/json');
      if (isJson) {
        const responseData = await response.json();
        return responseData;
      }
      return null; // No content returned
    } catch (error) {
      console.error("Error submitting request:", error);
      throw error;
    }
  };
  