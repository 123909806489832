import React, { useState } from "react";
import { ScreeningModel } from "../models/ScreeningModel";
import { UserInformationModel } from "../models/UserInformationModel";
import { submitData } from "../../services/ApexExternalAPI"; // Import the service
import IPFetcher from '../common/IPFetcher';
import LocationFetcher from '../common/LocationFetcher';
import { Link } from 'react-router-dom';
const Screening = () => {
  const [responses, setResponses] = useState(() => new ScreeningModel());
  const [userInfo, setUserInfo] = useState(() => new UserInformationModel());
  const [message, setMessage] = useState('');
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [showBookingOption, setShowBookingOption] = useState(false);
  const [error, setError] = useState('');
  const [userIP, setUserIP] = useState('');
  const [showQuestionButton, setShowQuestionButton] = useState(false); // New state for Question button visibility

  const [locationData, setLocation] = useState({
    fullAddress: '',
    city: '',
    state: '',
    country: ''
  });

  const handleLocationFetched = (data) => {
    setLocation(data);
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      userLocation: data.fullAddress,
    }));
  };

  const handleIP = (ip) => {
    setUserIP(ip);
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      userIPAddress: ip,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!responses.veinConcern || !responses.familyHistory || !responses.pastTreatment) {
      setError('Please answer all required questions before submitting.');
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    } else {
      setError('');
    }

    if (
      (responses.veinConcern === 'Cosmetic' || responses.veinConcern === 'None') &&
      responses.familyHistory === 'No' &&
      responses.pastTreatment === 'No' &&
      (responses.conditions.includes('None of the above') || responses.conditions.length === 0) &&
      (responses.symptoms.includes('None of the above') || responses.symptoms.length === 0)
    ) {
      setMessage('You seem to be healthy');
      setShowBookingOption(false);
      setShowRecommendations(false);
    } else {
      setMessage(
        'A consultation with a Vascular Surgeon including ultrasound to measure the blood flow in the veins and functioning of the veins is required for further evaluation. Would you like to schedule the consultation with Dr. Meka?'
      );
      setShowBookingOption(true);
      setShowRecommendations(false);
    }

    setShowQuestionButton(true); // Show the Question? button after submission

    const ScreeningDTO = {
      screening: responses,
      userInformation: userInfo,
    };

    try {
      const response = await submitData(ScreeningDTO, "Screening");
    } catch (error) {
      console.error("Failed to submit questionnaire:", error);
    }
  };

  const handleBookingResponse = (response) => {
    if (response === 'Yes') {
      setMessage('Thank you! Please click on the button below to book your consultation.');
    } else {
      setShowRecommendations(true);
      setShowBookingOption(false);
      setMessage(
        'If you do not want to schedule the appointment, Dr. Meka recommends the following to extend the life of the blood vessels.'
      );
    }
  };

  const handleRadioChange = (e) => {
    setResponses({ ...responses, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    if (checked) {
      setResponses({ ...responses, [name]: [...responses[name], value] });
    } else {
      setResponses({ ...responses, [name]: responses[name].filter((item) => item !== value) });
    }
  };

  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  return (
    <div className="container mt-5">
      <IPFetcher onIPReceived={handleIP} />
      <LocationFetcher onLocationUpdate={handleLocationFetched} />

      <h2 className="headings">Vein Health Screening</h2>
      <hr />
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-12">
          <div className="card shadow-sm p-4 mb-5 bg-white rounded">
            <form onSubmit={handleSubmit} className="form__section">
              {error && <div className="alert alert-danger">{error}</div>}

               {/* Question 1 */}
               <div className="question mb-4 p-3 border rounded">
                <h4 className="text-xl fw-600">1. Is your vein concern medical, cosmetic or both?</h4>
                <div className="d-flex flex-column">
                  {["Medical", "Cosmetic", "Both Medical & Cosmetic"].map((option, index) => (
                    <label key={index} className="form-check-label mb-2">
                      <input
                        type="radio"
                        name="veinConcern"
                        value={option}
                        onChange={handleRadioChange}
                        className="form-check-input me-2"
                        checked={responses.veinConcern === option}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                {responses.veinConcern === "Cosmetic" && (
                  <p className="alert alert-info mt-3">Information: Sclerotherapy can be used for cosmetic concerns.</p>
                )}
              </div>
              {/* Question 2 */}
              <div className="question mb-4 p-3 border rounded">
                <h4 className="text-xl fw-600">2. What conditions have you noticed on your legs? (check all that apply)</h4>
                <div className="row">
                  {["Varicose vein", "Spider veins", "Ankle swelling", "Skin changes", "Wounds/ulcers", "None of the above"].map((condition, index) => (
                    <div key={index} className="col-md-6 col-sm-12">
                      <label className="form-check-label mb-2">
                        <input
                          type="checkbox"
                          name="conditions"
                          value={condition}
                          onChange={handleCheckboxChange}
                          className="form-check-input me-2"
                          checked={responses.conditions.includes(condition)}
                        />
                        {condition}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

               {/* Question 3 */}
               <div className="question mb-4 p-3 border rounded">
                <h4 className="text-xl fw-600">3. Do you sometimes or often experience: (check all that apply)</h4>
                <div className="row">
                  {[
                    "Itching",
                    "Heaviness or tiredness",
                    "Aches, pain or throbbing",
                    "Night cramps",
                    "Restless legs",
                    "Burning or shooting pain down your leg(s)",
                    "Other",
                    "None of the above",
                  ].map((symptom, index) => (
                    <div key={index} className="col-md-6 col-sm-12">
                      <label className="form-check-label mb-2">
                        <input type="checkbox" name="symptoms" value={symptom} onChange={handleCheckboxChange} className="form-check-input me-2" />
                        {symptom}
                      </label>
                    </div>
                  ))}
                </div>
                {responses.symptoms.includes("Other") && (
                  <div className="mt-3">
                    <label className="d-block">
                      Please describe:{" "}
                      <input type="text" name="otherSymptom" value={responses.otherSymptom} onChange={(e) => setResponses({ ...responses, otherSymptom: e.target.value })} className="form-control" />
                    </label>
                  </div>
                )}
              </div>

              {/* Question 4 */}
              <div className="question mb-4 p-3 border rounded">
                <h4 className="text-xl fw-600">4. Have you or your family member been treated for blood clots in legs or lungs?</h4>
                <div className="d-flex flex-column">
                  <label className="form-check-label mb-2">
                    <input type="radio" name="familyHistory" value="Yes" onChange={handleRadioChange} className="form-check-input me-2" />
                    Yes
                  </label>
                  <label className="form-check-label">
                    <input type="radio" name="familyHistory" value="No" onChange={handleRadioChange} className="form-check-input me-2" />
                    No
                  </label>
                </div>
              </div>

              {/* Question 5 */}
              <div className="question mb-4 p-3 border rounded">
                <h4 className="text-xl fw-600">5. Have you been treated for varicose veins in the past?</h4>
                <div className="d-flex flex-column">
                  <label className="form-check-label mb-2">
                    <input type="radio" name="pastTreatment" value="Yes" onChange={handleRadioChange} className="form-check-input me-2" />
                    Yes
                  </label>
                  <label className="form-check-label">
                    <input type="radio" name="pastTreatment" value="No" onChange={handleRadioChange} className="form-check-input me-2" />
                    No
                  </label>
                </div>
              </div>


              {/* Submit button */}
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn schedule-btn col-lg-2">Submit</button>
              </div>
            </form>

            {message && (
              <div className="alert alert-info text-center mt-4">
                <p>{message}</p>
                {showBookingOption && (
                  <div className="d-flex justify-content-center">
                    <a
                      target="_blank"
                      href="https://provider.kareo.com/madhavi-meka#?view=booking#booking"
                      className="btn schedule-btn mb-2 me-2"
                    >
                      Schedule Now
                    </a>
                    <button
                      className="btn No-btn mb-2 col-lg-2 me-2"
                      onClick={() => handleBookingResponse('No')}
                    >
                      No
                    </button>
                  </div>
                )}
                {showQuestionButton && (
                  <div className="d-flex justify-content-center">
                  
                    <Link className="btn call-btn mb-2" to="/contact">
                        Question?
                    </Link>
                  </div>
                )}
              </div>
            )}

            {showRecommendations && (
              <div className="alert alert-warning mt-4">
                <h4>Dr. Meka recommends the following:</h4>
                <ul>
                  {/* Recommendations List */}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screening;
