import { useEffect } from 'react';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { submitData } from "../../services/ApexExternalAPI";
const useUserTracking = () => {
 
    useEffect(() => {
      ReactGA.initialize('G-MDLBJ02JMC');
      ReactGA.send('pageview');
      let deviceType = 'desktop';
      if (isMobile) deviceType = 'mobile';
      else if (isTablet) deviceType = 'tablet';
      localStorage.removeItem('userTracked');

        const trackUser = async () => {
          // Check if the user has already been tracked
          if (!localStorage.getItem('userTracked')) {  // Fixed condition to prevent re-tracking
            try {
              // Get the user's IP address
              const ipResponse = await axios.get('https://api.ipify.org?format=json');
              const userIP = ipResponse.data.ip;
    
              // Get user's location based on IP address
              const locationResponse = await axios.get(`https://ipapi.co/${userIP}/json/`);
              const locationData = locationResponse.data;
              const requestedHostName = "apexapexveinandvascular.com";
              // Prepare user information
              const userInformation = {
                userIPAddress: userIP,
                userLocation: `${locationData.city || ''}, ${locationData.region || ''}, ${locationData.postal || ''}, ${locationData.country_name || ''}`,
                hostRequestName:requestedHostName,
                deviceName:deviceType
              };
    
              // Send the data to your API
              await submitData(userInformation, 'UserInformation');
    
              // Store tracking data in localStorage
  
              localStorage.setItem('userTracked', JSON.stringify(userInformation));
            } catch (error) {
              console.error('Error fetching user IP or location:', error);
            }
          } else {
            try {
              const savedData = JSON.parse(localStorage.getItem('userTracked'));
            
              if (savedData) {
                console.log("Retrieved Data:", savedData);
              } else {
                console.log("No data found in localStorage.");
              }
            } catch (error) {
              console.error("Error parsing JSON from localStorage:", error);
            }
          }
        };
    
        // Trigger the user tracking function when the component mounts
        trackUser();
      }, []); // Empty dependency array ensures this effect runs once
    };
export default useUserTracking;
