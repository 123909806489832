// src/components/Footer.js
import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube, FaNewspaper } from 'react-icons/fa';
import './Footer.css'; // Create a separate CSS file for footer styles
import { FaSquareYoutube } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>Apex Vein & Vascular Institute</p>
          <p>4550 E Bell Rd</p>
          <p>Building 8, Suite 280</p>
          <p>Phoenix, AZ 85032</p>
          <p>Phone: <a href="tel:623 252 4495">623 252 4495</a></p>
          <p>Email: <a href="mailto:bannerlegs@gmail.com">bannerlegs@gmail.com</a></p>
        </div>

        <div className="footer-section">
          <h3>Our Location</h3>
          <div className="map-container">
            <iframe
              title="Apex Vein & Vascular Institute Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.605782325587!2d-111.98572148479572!3d33.641462180719635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b7165f4b46e65%3A0x47add128af6eb56d!2s4550%20E%20Bell%20Rd%20STE%20280%2C%20Phoenix%2C%20AZ%2085032!5e0!3m2!1sen!2sus!4v1680299302809!5m2!1sen!2sus"
              width="100%"
              height="200"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
            ></iframe>
          </div>
        </div>

        <div className="footer-section">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/ApexVein/" title='Facebook' target="_blank" rel="noreferrer">
              <FaFacebookF />
            </a>
           
            <a href="https://www.instagram.com/apexveinandvascular/" title='Instagram' target="_blank" rel="noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.youtube.com/watch?v=KfA4HNZGwlI" title='Youtube' target="_blank" rel="noreferrer">
              <FaSquareYoutube />
            </a>
            <a href="https://www.abc15.com/lifestyle/sonoran-living/sl-sponsors/apex-vein-and-vascular-institute-offers-many-treatments-for-unsightly-veins-and-vascular-issues" title='News' target="_blank" rel="noreferrer">
              <FaNewspaper />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom text-white">
  <p className="m-2 d-inline">
    © 2024 Apex Vein & Vascular Institute. All rights reserved.
  </p>
  
  <Link className="text-white text-decoration-underline ms-2" to="/PrivacyPolicy">
  Privacy Policy
          </Link>
</div>



    </footer>
  );
};

export default Footer;
