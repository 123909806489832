export class UserInformationModel {
    constructor() {
        this.userLocation = "";
        this.userIPAddress = ""; 
        this.hostRequestName ="";
        this.deviceName="";
      }
  
 // Method to convert to JSON for the API request
 toJSON() {
    return {
        userLocation: this.userLocation,
        userIPAddress: this.userIPAddress,
        hostRequestName: this.hostRequestName,
        deviceName:this.deviceName
    };
  }

}